<template>
  <v-dialog v-model="tagDialog" width="500">
    <v-card>
      <v-card-title class="text-h5 primary lighten-2">
        Add New Tag
      </v-card-title>

      <v-card-text>
        <v-col cols="12">
            <v-text-field outlined v-model="newTag.tag_id" label="Tag Name"></v-text-field>
        </v-col>
      </v-card-text>

      <v-divider></v-divider>

			<v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addNewTag" :disabled="!newTag.tag_id">
            <v-icon class="mr-1">mdi-plus</v-icon> Add
        </v-btn>
        <v-btn @click="closeDialog">
            Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import httpRequest from "@/plugins/httpService";

export default {
  name: 'AddTagDialog',
  props: {
    data_id: String,
    message_id: String,
    tagDialog: Boolean
  },
  data: () => ({
    newTag: {
      tag_id: null
    }
  }),
  
  methods: {
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage"
    }),
    
    async addNewTag(){
      await httpRequest.addTag(this.data_id,this.newTag.tag_id,this.message_id);
      this.addSuccessMessage('Successfully added tag!');
      this.newTag = {
        message_id: null,
        tag_id: null
      };
      this.$emit('closeAddTagDialog', true);
    },

    closeDialog(){
      this.$emit('closeAddTagDialog', false);
    }

  }
};
</script>