<template>
  <div>
    <v-app-bar dense fixed style="margin-top: 65px;">
      <v-app-bar-title>{{formatName(data.id)}}</v-app-bar-title>
      <v-spacer />
      
      <BulkTagDialog v-if="bulkTag" :data_id="data.id" :taggedMessages="results.filter(r => { return r.tagged })" :tagsUnique="tagsUnique" @closeBulkTagDialog="onCloseBulkTagDialog" />

      <FooterSelection :segment="data.id" />
      <v-btn small class="ml-1" color="secondary" :disabled="loadingCsv" @click="prepareCsvResults">
        <v-icon>mdi-download</v-icon> Download Results
      </v-btn>
      <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-switch v-model="bulkTag" inset label="Bulk Tagging"></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch v-model="calendar.show" inset label="Show Calendar"></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch v-model="condensed" inset label="Condensed Mode"></v-switch>
            </v-list-item>
          </v-list>
      </v-menu>
    </v-app-bar>
      
  <div style="min-height: 100vh;margin-top: 50px;">
    <SegmentFilters :tags="tagsUnique" @toggleSegmentFilters="onToggleSegmentFilters" @updateFilters="getSearchResults" />
    
    <div class="pt-2" :class="{ 'nav-open': showNav, 'nav-closed': !showNav }">
      
      <div class="pl-7 pr-7" v-if="calendar.show && calendar.dates.length > 0 && !loading">
        <CalendarHeatMap :dates="calendar.dates" />
      </div>
      
      <EmailThread :results="activeThread" :thread="thread" :bulkTag="bulkTag" :condensed="condensed" :tagsUnique="tagsUnique" @resetThread="onResetThread" @closeThread="onCloseThread" />

      <v-dialog v-model="notesDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 primary lighten-2">
            Notes
          </v-card-title>

          <v-card-text>
            <v-textarea class="mt-5 mb-2" outlined name="notes" label="Notes" v-model="newNote">
            </v-textarea>
            <div class="d-flex justify-end mt-n7 mb-3">
              <v-btn small color="secondary" :disabled="!newNote" @click="addNewNote">
                <v-icon> mdi-plus</v-icon> Add Note
              </v-btn>
            </div>
            <v-list>
              <template v-for="(item,index) in currentNoteItem.item._source.notesArray">
                <v-divider :key="index" />
                <v-list-item two-line :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{item.text}}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.author">{{item.date}} by {{item.author}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              
              <v-list-item v-if="currentNoteItem.item._source.notesArray && currentNoteItem.item._source.notesArray.length == 0">
                  <v-list-item-content>
                    <v-list-item-title>No Notes Added</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="notesDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <AddTagDialog :tagDialog="tagDialog" :data_id="data.id" :message_id="taggingMessageId" @closeAddTagDialog="onCloseAddTagDialog" />

      <div class="d-flex justify-center align-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <v-card class="ma-5" v-if="results.length > 0">
        <EmailSearchResults 
            :condensed="condensed" 
            :results="results" 
            :keywords="search[data.id].keywords" 
            :externalPaging="true" 
            :totalItems="totalItems"
            @tagsChanged="getTags"
            @pageChange="pagedSearch"
            @sortChanged="onSortChanged">
          <template v-slot:actions="props">
            <div class="d-flex flow-row">
              <v-btn fab small color="primary" class="mr-1" @click="setActiveThread(props.item)" title="View Thread"><v-icon>mdi-message-text</v-icon></v-btn>
              <v-btn fab small color="primary" class="mr-1" @click="viewNotes(props.item)" title="Notes">
                <v-badge dot color="secondary" v-if="props.item.item._source.notes && props.item.item._source.notes.length > 0">
                  <v-icon>mdi-note</v-icon>
                </v-badge>
                <v-icon v-else>mdi-note</v-icon>
              </v-btn>
              <!-- TAGS -->
              <div class="pl-1" v-if="bulkTag">
                <v-checkbox class="mt-1" v-model="props.item.item.tagged"></v-checkbox>
              </div>
              <v-menu offset-y left v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab small color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-tag-multiple</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <template v-for="(tag, index) in tagsUnique">
                    <v-list-item @click="tagMessage(tag, props.item)" :key="index" v-if="checkifAlreadyTagged(props.item, tag)">
                      <v-list-item-title>{{ tag }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item @click="showTagDialog(props.item)">
                    <v-list-item-title>(Add New Tag)</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </EmailSearchResults>
      </v-card>
      
      <v-card class="ma-5 pa-5 text-center" v-if="!loading && results.length == 0 && local.attempted">
        <h3>No Results Found</h3>
        <p>Please refine your search and try again.</p>
      </v-card>
      
    </div>

  </div>

  </div>
  
</template>

<script>
import * as axios from "axios";
import { mapActions, mapGetters } from "vuex";
import _uniqBy from 'lodash/uniqBy';
import httpRequest from "@/plugins/httpService";
import indexNaming from "@/plugins/indexNaming.js";
import notesHandler from "@/plugins/notesHandler";
import AddTagDialog from "@/components/Tagging/AddTagDialog";
import BulkTagDialog from "@/components/Tagging/BulkTagDialog";
import CalendarHeatMap from "@/components/CalendarHeatMap";
import EmailSearchResults from "@/components/EmailSearchResults";
import EmailThread from "@/components/EmailThread";
import FooterSelection from "@/components/FooterSelection";
import SegmentFilters from "@/components/Segment/SegmentFilters";

export default {
  name: 'SegmentEmailSearch',
  components: {
    AddTagDialog,
    BulkTagDialog,
    CalendarHeatMap,
    EmailSearchResults,
    EmailThread,
    FooterSelection,
    SegmentFilters
  },
  data: () => ({
    activeThread: [],
    calendar: {
      dates: [],
      show: true
    },
    bulkTag: false,
    condensed: true,
    currentNoteItem: { item: { _source: {}}},
    data: {},
    loading: false,
    loadingCsv: false,
    local: {
      queries: [],
      attempted: false,
      senders: [],
      receivers: [],
      keywords: []
    },
    newNote: '',
    notesDialog: false,
    receivers: [],
    results: [],
    showNav: false,
    tagDialog: false,
    taggingMessageId: null,
    tags: [],
    tagsUnique: [],
    thread: false,
    totalItems: 0
  }),
  
  props: {
    dataId: String
  },

  computed: {   
    ...mapGetters({
      search: "app/search",
      user: "app/user"
    }),
    
    csvTitle(){
      return this.formatName(this.data.id).split(' ').join('_') + '_SearchResults.csv';
    }
  },

  methods: {
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage",
      addErrorMessage: "app/addErrorMessage",
      setSearchDates: "app/setSearchDates",
      setSearchPaging: "app/setSearchPaging"
    }),

    async addNewNote() {

      const noteToAdd = this.newNote + notesHandler.delimiter() + new Date().toISOString().substring(0,10) + notesHandler.delimiter() + this.user.name;
      await httpRequest.addNote(this.currentNoteItem.item._id, noteToAdd);

      this.currentNoteItem.item._source.notes.push(noteToAdd);

      this.currentNoteItem.item._source.notesArray.push({
        text: this.newNote,
        date: new Date().toISOString().substring(0,10),
        author: this.user.name
      });

      this.newNote = '';
      this.addSuccessMessage('Successfully added note!');

    },

    checkifAlreadyTagged(item, tag){
      return !item.item.tags.some(s => { return s.tag_id == tag });
    },

    async downloadItem (url) {
      const localRequest = axios.create();
      const response = await localRequest.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.csvTitle;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async getSearchResults(resetPaging) {

      this.loadingCsv = false;

      if (resetPaging){
        this.results = [];
        this.totalItems = 0;
        this.local.pagingSize = 10;
        this.local.pagingFrom = 0;
        this.local.pagingOrder = 'asc';
      }

      this.loading = true;
      this.local.attempted = true;

      let ids = [];
      if (this.search[this.data.id].tags.length > 0){
        ids = this.tags.filter(t => { return this.search[this.data.id].tags.includes(t.tag_id); }).map(m => { return m.message_id; });
      }

      this.setSearchPaging({id: this.data.id, pagingSize: this.local.pagingSize, pagingFrom: this.local.pagingFrom, pagingOrder: this.local.pagingOrder});
      const response = await httpRequest.getEmailSearchResults(this.search[this.data.id], this.data.id, this.local.pagingSize, ids);

      const localTags = this.tags;
      
      if (response && response.data && response.data.data && response.data.data.hits){
        this.results = response.data.data.hits.hits.map(h => {
          h.tags = localTags.filter(t => { return t.message_id == h._id });
          return h;
        });
        this.totalItems = response.data.data.hits.total.value;
      } else {
        this.results = [];
        this.totalItems = 0;
      }

      if (response && response.data && response.data.data && response.data.data.aggregations && response.data.data.aggregations.sent_date){
        this.calendar.dates = response.data.data.aggregations.sent_date.buckets.map(r => {

          const dataDate = new Date(r.key_as_string.substring(0,10))

          return {
            date: dataDate.setDate(dataDate.getDate() + 1), // handle the offset to display proper dates
            count: r.doc_count
          }
        });
      } else {
        this.calendar.dates = [];
      }
      
      this.loading = false; 
    },

    formatName(index){
      return indexNaming.format(index);
    },

    async getTags() {

      this.tags = [];
      const response = await httpRequest.getTags(this.data.id);
      this.tags = ((response.data || {}).data || []);
      this.tagsUnique = _uniqBy(this.tags, 'tag_id').map(t => { return t.tag_id });

    },

    async initData() {

      this.loading = true;

      if (this.search[this.data.id]){ 
        this.local = {
          attempted: false,
          pagingSize: (this.search[this.data.id].pagingSize || 10),
          pagingFrom: (this.search[this.data.id].pagingFrom || 0),
          pagingOrder: (this.search[this.data.id].pagingOrder || 'asc')
        };
      }
      await this.getTags();
      this.getSearchResults(true);
    },

    onCloseAddTagDialog(resetTagging){
      this.tagDialog = false;
      this.taggingMessageId = null;
      if (resetTagging){
        this.resetTagging();
      }
    },

    onCloseBulkTagDialog(resetTagging){
      if (resetTagging){
        this.resetTagging();
      }
    },

    onCloseThread(changedTags){
      console.log(changedTags);

      if (changedTags){
        this.resetTagging();
      }

      this.thread = false;
    },

    async onResetThread(){
      await this.getTags();
      const localTags = this.tags;
      this.activeThread = this.activeThread.map(h => {
        h.tags = localTags.filter(t => { return t.message_id == h._id });
        return h;
      });
    },

    onSortChanged(sortDesc){
      this.local.pagingOrder = (sortDesc ? 'desc':'asc');
      this.getSearchResults();
    },

    onToggleSegmentFilters(){
      this.showNav = !this.showNav;
    },

    pagedSearch(paging){
      this.local.pagingSize = paging.itemsPerPage;
      this.local.pagingFrom = paging.pageStart;
      this.getSearchResults();
    },

    async prepareCsvResults(){
      
      this.loadingCsv = true;
      
      let ids = [];
      if (this.search[this.data.id].tags.length > 0){
        ids = this.tags.filter(t => { return this.search[this.data.id].tags.includes(t.tag_id); }).map(m => { return m.message_id; });
      }

      const response = await httpRequest.getEmailSearchResultsForDownload(this.search[this.data.id], this.data.id, 10000, ids, this.tags);
      
      if (response && response.data && response.data.data){
        await this.downloadItem(response.data.data);
      } else {
        this.addErrorMessage('Unable to generate download file!');
      }
      
      this.loadingCsv = false;
    },

    async resetTagging(){
      await this.getTags();
      this.getSearchResults();
    },

    async setActiveThread(msg){
      const localTags = this.tags;
      const response = await httpRequest.getThread(msg.item._source.thread_group, this.data.id);
      this.activeThread = response.data.data.hits.hits.map(h => {
        h.tags = localTags.filter(t => { return t.message_id == h._id });
        return h;
      });
      window.scrollTo(0,0); // because of the absolute nav drawer, it can look weird if you are scrolled down
      this.thread = true;
    },

    showTagDialog(message){
      this.taggingMessageId = message.item._id;
      this.tagDialog = true;
    },

    async tagMessage(tag, message){

      const new_tag = {
        active: true,
        id: null,
        segment_id: this.data.id,
        tag_id: tag
      }

      const response = await httpRequest.addTag(this.data.id,tag,message.item._id);
      new_tag.id = response.data.data.id;
      message.item.tags.push(new_tag);
      
      this.addSuccessMessage('Successfully added tag!');
      
      await this.getTags();

    },

    viewNotes(msg){
      this.currentNoteItem = msg;
      if (!this.currentNoteItem.item._source.notes){ this.currentNoteItem.item._source.notes = []; }
      if (!this.currentNoteItem.item._source.notesArray){ this.currentNoteItem.item._source.notesArray = []; }
      
      this.currentNoteItem.item._source.notesArray = this.currentNoteItem.item._source.notes.map(n => {
         
        const notesArray = n.split(notesHandler.delimiter());
        return {
          text: notesArray[0],
          date: notesArray[1],
          author: notesArray[2]
        }
      });
      
      this.notesDialog = true;
    }
  },

  created() {
    this.data.id = this.dataId;
    this.initData();
  }
};
</script>
<style scoped>

  .mt-header {
    margin-top: 65px;
  }

  .nav-closed {
    padding-left: 56px;
  }

  .nav-open {
    padding-left: 30%;
  }

</style>