export default {

  delimiter: function() {
    return '|!!|';
  },
  
  getAttachmentsForCsv: function(_source) {
    
    if (!_source){ return ''; }
    if (!_source.attachments){ return ''; }
    if (_source.attachments.length){ return ''; }
    return _source.attachments.map(a => {
        return a.filename;
    });
  },
  
  getNoteForCsv: function(notes) {
    
    if (!notes || typeof(notes) == 'string'){ notes = []; }
    return notes.map(n => {
        const note = n.split(this.delimiter());
        return note[0].replaceAll('\n',' ').replaceAll(/"/g, "'").replace(/\s\s+/g, ' ');
    });
  }

};
