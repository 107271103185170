<template> 
  <v-navigation-drawer :mini-variant="mini" absolute left width="30%" class="pl-4 pr-2">
    <v-btn icon small class="mt-2" @click="toggleNav">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <div style="float: right;" v-if="!mini">
      <v-btn tile x-small class="mt-2 pa-2" @click="panels = [];" title="Collapse All">
        <v-icon small>mdi-arrow-collapse-up</v-icon>
      </v-btn>
      <v-btn tile x-small class="mt-2" @click="panels = [0,1,2,3,4];" title="Expand All">
        <v-icon small>mdi-arrow-collapse-down</v-icon>
      </v-btn>
    </div>

    <v-expansion-panels accordion multiple tile class="mt-5" v-if="mini">
      <v-expansion-panel>
        <v-expansion-panel-header class="pl-0" @click="toggleNav">
          <v-badge dot color="success" style="flex: none;" class="mr-5" :value="(local.keywords || []).length > 0">
            <v-icon>mdi-alpha-k-circle-outline</v-icon>
          </v-badge>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="pl-0" @click="toggleNav">
          <v-badge dot color="success" style="flex: none;" class="mr-5" :value="hasTagsSearch"><v-icon>mdi-tag</v-icon></v-badge>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="pl-0" @click="toggleNav">
          <v-badge dot color="success" style="flex: none;" class="mr-5" :value="hasDateSearch"><v-icon>mdi-calendar</v-icon></v-badge>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="pl-0" @click="toggleNav">
          <v-badge dot color="success" style="flex: none;" class="mr-5" :value="(local.senders || []).length > 0"><v-icon>mdi-email-send</v-icon></v-badge>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="pl-0" @click="toggleNav">
          <v-badge dot color="success" style="flex: none;" class="mr-5" :value="(local.receivers || []).length > 0"><v-icon>mdi-email-receive</v-icon></v-badge>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels v-model="panels" accordion multiple tile class="mt-5" v-else>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-badge dot color="success" style="flex: none;" :value="(local.keywords || []).length > 0">Keyword Search</v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field dense outlined label="Search" v-model="local.text" hide-details class="mb-2" autocomplete="off" v-on:keyup.enter="setFilters"></v-text-field>
          <v-chip small close color="primary" outlined @click:close="removeKeyword(k)" class="ma-1" v-for="k in local.keywords" :key="k">
              {{k}}
            </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-badge dot color="success" style="flex: none;" :value="hasTagsSearch">Tag Search</v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item-group v-model="local.tags" multiple>
              <v-list-item v-for="(i) in tags" :key="i" :value="i">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title v-text="i"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary"></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-badge dot color="success" style="flex: none;" :value="hasDateSearch">Dates</v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field dense outlined clearable label="Start Date" v-model="local.startDate" type="date" hide-details class="mb-2"></v-text-field>
          <v-text-field dense outlined clearable label="End Date" v-model="local.endDate" type="date" hide-details class="mb-2"></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-badge dot color="success" style="flex: none;" :value="(local.senders || []).length > 0">Senders</v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Avatars :avatars="senders" :dataId="data.id" :sender="true" @avatarAdded="initData" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-badge dot color="success" style="flex: none;" :value="(local.receivers || []).length > 0">Receivers</v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Avatars :avatars="receivers" :dataId="data.id" :sender="false" @avatarAdded="initData" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-if="!mini">
        <v-btn class="mt-2" color="secondary" dense block @click="setFilters">
            <v-icon class="mr-1">mdi-magnify</v-icon>Search
        </v-btn>
    </div>    
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import avatarService from "@/plugins/avatarService";
import Avatars from "@/components/Segment/Avatars";

export default {
  name: 'SegmentFilters',
  components: {
    Avatars
  },
  props: {
    tags: Array
  },
  data: () => ({
    data: {},
    local: {
      text: '',
      queries: [],
      senders: [],
      receivers: [],
      tags: []
    },
    mini: true,
    panels: [],
    receivers: [],
    senders: []
  }),
  
  computed: {   
    ...mapGetters({
      search: "app/search"
    }),
    
    hasDateSearch() {
      return this.local.startDate != null || this.local.endDate != null;
    },

    hasTagsSearch(){
      return (this.local.tags || []).length > 0;
    }
  },

  methods: {
    ...mapActions({
      addSearchKeyword: "app/addSearchKeyword",
      removeSearchKeyword: "app/removeSearchKeyword",
      setSearchDates: "app/setSearchDates",
      setSearchTags: "app/setSearchTags"
    }),

    async initData() {

      this.receivers = await avatarService.getReceiversEmailList(this.data.id);
      this.senders = await avatarService.getSendersEmailList(this.data.id);

      if (this.search[this.data.id]){ 
        this.local = {
          text: this.search[this.data.id].text,
          queries: this.search[this.data.id].queries,
          startDate: this.search[this.data.id].startDate,
          endDate: this.search[this.data.id].endDate,
          receivers: this.search[this.data.id].receivers,
          senders: this.search[this.data.id].senders,
          keywords: this.search[this.data.id].keywords,
          tags: this.search[this.data.id].tags
        };
      }
    },

    removeKeyword(keyword){
      this.removeSearchKeyword({id: this.data.id, keyword: keyword});
      this.$emit('updateFilters', true);
    },
    
    setFilters() {

      if (this.local.text){
        this.addSearchKeyword({id: this.data.id, keyword: this.local.text}); 
        this.local.text = '';
      }

      this.setSearchDates({id: this.data.id, startDate: this.local.startDate, endDate: this.local.endDate});
      this.setSearchTags({id: this.data.id, tags: this.local.tags});
      
      this.$emit('updateFilters', true);

    },
    
    toggleNav() {
      this.mini = !this.mini;
      this.$emit('toggleSegmentFilters');
    }
    
  },

  created() {
    this.data.id = this.$route.params.id;
    this.initData();
  }
};
</script>