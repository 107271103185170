<template> 
  <v-data-table 
      :headers="headers" 
      :items="results" 
      :page.sync="page"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="totalItems"
      @update:items-per-page="triggerPaging"
      @update:page="triggerPaging"
      @update:sort-desc="triggerResort"
      item-key="id" 
      class="elevation-1 pa-5">  
    <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer 
          style="border-top: none; margin-top: -15px;"
          :pagination="pagination" 
          :options="options" 
          @update:options="updateOptions" 
          items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
    </template>
    <template slot="item.Sender" slot-scope="props">
        <text-highlight :queries="keywords" :wholeWordMatch="true" v-if="keywords">{{ props.item._source.sender_address }}</text-highlight>
        <span v-else>{{ props.item._source.sender_address }}</span>
    </template>
    <template slot="item.Recipient" slot-scope="props">
        <text-highlight :queries="keywords" :wholeWordMatch="true" v-if="keywords">{{ props.item._source.recipient_address.join(", ") }}</text-highlight>
        <span v-else>{{ props.item._source.recipient_address.join(", ") }}</span>
    </template>
    <template slot="item.sentDate" slot-scope="props">
        {{ formatDate(props.item._source.sent_date) }}
    </template>
    <template slot="item.SubjectBody" slot-scope="props">
        <span :class="{'email-body-spaced': !condensed, 'clamped': props.item.clamped}">
          <strong>
            <text-highlight :queries="keywords" :wholeWordMatch="true" v-if="keywords">{{props.item._source.subject}}</text-highlight>
            <span v-else>{{props.item._source.subject}}</span>
          </strong> | 
          <text-highlight :queries="keywords" :wholeWordMatch="true" v-if="keywords">{{ props.item._source.body }}</text-highlight>
          <span v-else>{{ props.item._source.body }}</span>
        </span>
        <div v-if="props.item.tags && props.item.tags.length > 0">
          <v-chip class="ma-2" color="secondary" label close text-color="white" @click:close="removeTag(t, index, props.item)" v-for="(t,index) in props.item.tags" :key="index">
            {{t.tag_id}}
          </v-chip>
        </div>
        <div v-if="props.item._source.attachments && props.item._source.attachments != '{}' && props.item._source.attachments.length > 0">
          <v-chip class="ma-2" color="primary" label text-color="white" v-for="(r,index) in props.item._source.attachments" :key="index">
            <v-icon left>mdi-paperclip</v-icon>
            {{r.filename}}
          </v-chip>
        </div>
    </template>
    <template slot="item.actions" slot-scope="props">
      <slot name="actions" :item="props"></slot>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import _orderBy from 'lodash/orderBy';
import formatDate from '@/plugins/formatDate';
import httpRequest from "@/plugins/httpService";
import TextHighlight from 'vue-text-highlight';

export default {
  name: 'EmailThread',
  components: {
    TextHighlight
  },
  props: {
    condensed: Boolean,
    externalPaging: Boolean,
    keywords: Array,
    results: Array,
    totalItems: Number
  },
  data: () => ({
    headers: [
      { text: 'Sender', value: 'Sender', sortable: false, width: '20%' },
      { text: 'Recipient', value: 'Recipient', sortable: false, width: '15%' },
      { text: 'Sent Date', value: 'sentDate', sortable: true, width: '13%' },
      { text: 'Subject | Body', value: 'SubjectBody', sortable: false, width: '41%' },
      { text: 'Actions', value: 'actions', sortable: false, width: '11%' }
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    sortBy: 'sentDate',
    sortDesc: false
  }),
  
  computed: {   
  },

  methods: {
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage"
    }),

    customSort(items, index, isDesc) {
      let sortIndex = '';
      
      switch (index[0]) {
        case 'Sender':
          sortIndex = '_source.sender_address';
          break;
        case 'Recipient':
          sortIndex = '_source.recipient_address';
          break;
        case 'sentDate':
          sortIndex = '_source.sent_date';
          break;
        case 'SubjectBody':
          sortIndex = '_source.body';
          break;
      }
      return _orderBy(items, sortIndex, (isDesc[0] ? 'desc':'asc'));
    },

    formatDate(date) {
      return formatDate.formatWithTimeWithoutOffset(date);
    },

    async removeTag(tag, index, message){
      message.tags.splice(index, 1);
      await httpRequest.removeTag(tag.id);
      this.addSuccessMessage('Successfully removed tag ' + tag.tag_id + '!');
      this.$emit('tagsChanged');
    },

    triggerPaging(){
      if (this.externalPaging){
        this.$emit('pageChange', {
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          pageStart: (this.page - 1) * this.itemsPerPage
        });
      }
    },

    triggerResort(){
      if (this.externalPaging){
        this.$emit('sortChanged', this.sortDesc);
      }
    }
  }
};
</script>

<style scoped>

  .clamped {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .email-body-spaced {
    white-space: pre-wrap;
  }

  .v-data-footer__select {
    display: none;
  }

</style>
