<template>
  <div class="pa-10">
    <h1 class="mb-10">Select Data Set</h1>
    <v-row class="d-flex justify-center">
      <v-col cols="12" lg="6">
        <v-card v-if="!loading">
          <v-list three-line>
            <template v-for="(ds,index) in datasets">
              <v-list-item :key="ds.id" :to="{name: 'data-segment', params: {id: ds.index}}" class="button-link">
                <template>
                <v-list-item-content>
                  <v-list-item-title v-text="formatName(ds.index)" class="text-h4"></v-list-item-title>
                  <v-list-item-subtitle v-text="ds['pri.store.size']"></v-list-item-subtitle>
                  <v-list-item-subtitle>{{ds['docs.count']}} total documents</v-list-item-subtitle>
                </v-list-item-content>
                </template>
              </v-list-item>
              
              <v-divider v-if="index < datasets.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import httpRequest from "@/plugins/httpService";
import indexNaming from "@/plugins/indexNaming.js";

export default {
  name: 'Dashboard',
  data: () => ({
    datasets: []
  }),
  
  computed: {    
    ...mapGetters({
      loading: "app/loadingData"
    })
  },

  methods: {

    ...mapActions({
      setLoadingData: "app/setLoadingData"
    }),

    async initData() {
      this.setLoadingData(true);
      const response = await httpRequest.getDataSets();
      this.datasets = response.data.data;
      this.setLoadingData(false);
    },

    formatName(index){
      return indexNaming.format(index);
    }
  },

  created() {
    this.initData();
  }
};
</script>
