<template>
	<span>
    <v-btn small class="mr-1" color="primary" @click="bulkTagMessages">
      <v-icon>mdi-tag-multiple</v-icon> Tag Selected
    </v-btn>
      
    <v-dialog v-model="bulkTagDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 primary lighten-2">
          Bulk Tagging
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item-group v-model="selectedBulkTags" multiple>
              <template v-for="(item, i) in tagsUnique">
                <v-list-item :key="`item-${i}`" :value="item">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveBulkTags" :disabled="selectedBulkTags.length == 0">
            <v-icon class="mr-1">mdi-plus</v-icon> Add
          </v-btn>
          <v-btn @click="closeBulkTagDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapActions } from "vuex";
import httpRequest from "@/plugins/httpService";

export default {
  name: 'BulkTagDialog',
  props: {
    data_id: String,
    message_id: String,
    taggedMessages: Array,
    tagsUnique: Array
  },
  data: () => ({
    bulkTagDialog: false,
    selectedBulkTags: []
  }),
  
  methods: {
    ...mapActions({
      addErrorMessage: "app/addErrorMessage",
      addSuccessMessage: "app/addSuccessMessage"
    }),
    
    bulkTagMessages(){

      if (this.taggedMessages.length == 0) {
        this.addErrorMessage('No messages selected for tagging!');
      } else {
        this.bulkTagDialog = true;
      }
      
    },

    closeBulkTagDialog(){
      this.bulkTagDialog = false;
      this.selectedBulkTags = [];
    },
    
    async saveBulkTags(){
      const tagged_messages = this.taggedMessages;
      const selected = this.selectedBulkTags;
      const data_id = this.data_id;
      
      for (const a of tagged_messages){

        for (const b of selected){
          // check to make sure its not tagged already
          if (a.tags.filter(t => t.tag_id == b).length == 0){
            await httpRequest.addTag(data_id,b,a._id);
          }
        }
      }

      this.addSuccessMessage('Successfully added tags!');
      this.closeBulkTagDialog();
      this.$emit('closeBulkTagDialog', true);
    }
  }
};
</script>