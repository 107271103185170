import httpRequest from './httpService';
import _flatten from "lodash/flatten";
import _orderBy from "lodash/orderBy";

const getReceiversEmailList = async function(segment_id) {
    // get the list of email participants in this segment
    const response = await httpRequest.getParticipants(segment_id);
    const data = response.data.data.aggregations;
    const groupedData = Object.values([...data.sender_address.buckets].reduce((acc, { key, doc_count }) => {
      acc[key] = { key, doc_count: (acc[key] ? acc[key].doc_count : 0) + doc_count  };
      return acc;
    }, {}));

    // remove any that are already on this avatar
    const aResponse = await httpRequest.getAvatars(segment_id);
    let avatars = aResponse.data.data.map(m => {
        const emails = (groupedData.filter(f => { return m.associated_info.includes(f.key); }) || []);
        m.doc_count = emails.reduce((accumulator, object) => {
            return accumulator + object.doc_count;
        }, 0);
        return m;
    });
    const existingEmails = _flatten(aResponse.data.data.map(r => { return r.associated_info; }))

    const newEmails = groupedData.filter(f => { return !existingEmails.includes(f.key); });
    avatars = avatars.concat(newEmails.map(n => { return {
        id: 'new',
        avatar_name: n.key,
        avatar_type: 'person',
        avatar_image: null,
        associated_info: [].concat(n.key),
        doc_count: n.doc_count
    }
    }));
    return _orderBy(avatars, ['doc_count', 'avatar_name'], ['desc','asc']);
}

const getSendersEmailList = async function(segment_id) {
    // get the list of email participants in this segment
    const response = await httpRequest.getParticipants(segment_id);
    const data = response.data.data.aggregations;
    const groupedData = Object.values([...data.recipient_address.buckets, ...data.cc.buckets, ...data.bcc.buckets].reduce((acc, { key, doc_count }) => {
      acc[key] = { key, doc_count: (acc[key] ? acc[key].doc_count : 0) + doc_count  };
      return acc;
    }, {}));
    
    // remove any that are already on this avatar
    const aResponse = await httpRequest.getAvatars(segment_id);
    let avatars = aResponse.data.data.map(m => {
        const emails = (groupedData.filter(f => { return m.associated_info.includes(f.key); }) || []);
        m.doc_count = emails.reduce((accumulator, object) => {
            return accumulator + object.doc_count;
        }, 0);
        return m;
    });
    const existingEmails = _flatten(aResponse.data.data.map(r => { return r.associated_info; }));

    const newEmails = groupedData.filter(f => { return !existingEmails.includes(f.key); });
    avatars = avatars.concat(newEmails.map(n => { return {
        id: 'new',
        avatar_name: n.key,
        avatar_type: 'person',
        avatar_image: null,
        associated_info: [].concat(n.key),
        doc_count: n.doc_count
    }
    }));
    return _orderBy(avatars, ['doc_count', 'avatar_name'], ['desc','asc']);
}

export default {
    getReceiversEmailList,
    getSendersEmailList
};