<template>
  <v-navigation-drawer absolute temporary right width="50%" style="margin-top: -50px;" v-model="thread">
  
    <v-btn icon class="float-right mt-2" @click="closeThread"><v-icon>mdi-close</v-icon></v-btn>
    <h1 class="pa-2">Thread</h1>
    
    <BulkTagDialog class="ml-2" v-if="bulkTag" :data_id="data.id" :taggedMessages="results.filter(r => { return r.tagged })" :tagsUnique="tagsUnique" @closeBulkTagDialog="onCloseBulkTagDialog" />
    <FooterSelection :segment="data.id" />
    <vue-json-to-csv :json-data="csvResults" csv-title="Thread">
      <v-btn small color="secondary" class="ma-2">
        <v-icon>mdi-download</v-icon> Download Thread
      </v-btn>
    </vue-json-to-csv>
    <v-divider></v-divider>
    
    <div class="thread-scroll">
      <v-list three-line>
        <template v-for="(r,index) in sortedResults">
          <v-list-item :key="r._id">

            <v-list-item-content>
              <v-list-item-title>
                  <text-highlight :queries="search[data.id].keywords" :wholeWordMatch="true">{{ r._source.sender_address }}</text-highlight>
              </v-list-item-title>
              <v-list-item-subtitle><small>{{formatDate(r._source.sent_date)}}</small></v-list-item-subtitle>
              <span :class="{'email-body-spaced': !condensed}">
                  <strong><text-highlight :queries="search[data.id].keywords" :wholeWordMatch="true">{{r._source.subject}}</text-highlight></strong> | 
                  <text-highlight :queries="search[data.id].keywords" :wholeWordMatch="true">{{ r._source.body }}</text-highlight>
              </span>
              
              <div v-if="r.tags && r.tags.length > 0">
                <v-chip class="ma-2" color="secondary" label text-color="white" close @click:close="removeTag(t, index, r)" v-for="(t,index) in r.tags" :key="index">
                  {{t.tag_id}}
                </v-chip>
              </div>
            </v-list-item-content>
            
            <v-list-item-action>
              <!-- TAGS -->
              <div class="pl-1" v-if="bulkTag">
                <v-checkbox class="mt-1" v-model="r.tagged"></v-checkbox>
              </div>
              <v-menu offset-y left v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab small color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-tag-multiple</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <template v-for="(tag, index) in tagsUnique">
                    <v-list-item @click="tagMessage(tag, r)" :key="index" v-if="checkifAlreadyTagged(r, tag)">
                      <v-list-item-title>{{ tag }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item @click="showTagDialog(r)">
                    <v-list-item-title>(Add New Tag)</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        
          <v-divider v-if="index < results.length - 1" :key="index"></v-divider>
        </template>
      </v-list>
    </div>

    <AddTagDialog :tagDialog="tagDialog" :data_id="data.id" :message_id="taggingMessageId" @closeAddTagDialog="onCloseAddTagDialog" />

  </v-navigation-drawer>    
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _orderBy from 'lodash/orderBy';
import formatDate from '@/plugins/formatDate';
import httpRequest from "@/plugins/httpService";
import notesHandler from '@/plugins/notesHandler';
import AddTagDialog from "@/components/Tagging/AddTagDialog";
import BulkTagDialog from "@/components/Tagging/BulkTagDialog";
import FooterSelection from "@/components/FooterSelection";
import TextHighlight from 'vue-text-highlight';
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  name: 'EmailThread',
  components: {
    AddTagDialog,
    BulkTagDialog,
    FooterSelection,
    TextHighlight,
    VueJsonToCsv
  },
  props: {
    bulkTag: Boolean,
    condensed: Boolean,
    tagsUnique: Array,
    thread: Boolean,
    results: Array
  },
  data: () => ({
    changedTags: false,
    data: {},
    tagDialog: false,
    taggingMessageId: null
  }),
  
  computed: {   
    ...mapGetters({
      search: "app/search"
    }),

    csvResults(){
      return this.sortedResults.map(r => { 
        return {
          Id: r._id,
          external_id: r._source.external_id,
          Tags: r.tags.map(t => { return t.tag_id }).join(", "),
          Sender: r._source.sender_address.replaceAll(/"/g, "'"),
          Recipient: r._source.recipient_address.join(", ").replaceAll(/"/g, "'"),
          SentDate: r._source.sent_date,
          Subject: r._source.subject.replaceAll(/"/g, "'"),
          Body: r._source.body.replaceAll('\n',' ').replaceAll(/"/g, "'").replace(/\s\s+/g, ' '), // filter out breaks and spaces to cleanup download file
          Notes: notesHandler.getNoteForCsv(r._source.notes),
          Attachments: notesHandler.getAttachmentsForCsv(r._source)
        }
      })
    },
    
    sortedResults (){
      return _orderBy(this.results, ['_source.sent_date'], ['asc']);
    }

  },

  methods: {
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage"
    }),

    checkifAlreadyTagged(item,tag){
      return !item.tags.some(s => { return s.tag_id == tag });
    },

    closeThread(){
      const changed = this.changedTags;
      this.changedTags = false;
      this.$emit('closeThread', changed);
    },

    formatDate(date) {
      return formatDate.formatWithTimeWithoutOffset(date);
    },

    onCloseAddTagDialog(resetTagging) {
      this.tagDialog = false;
      this.taggingMessageId = null;

      if (resetTagging){
        this.changedTags = true;
        this.$emit('resetThread');
      }
    },

    onCloseBulkTagDialog(resetTagging){
      if (resetTagging){
        this.changedTags = true;
        this.$emit('resetThread');
      }
    },
    
    async removeTag(tag, index, message){
      message.tags.splice(index, 1);
      await httpRequest.removeTag(tag.id);
      this.addSuccessMessage('Successfully removed tag ' + tag.tag_id + '!');
      this.changedTags = true;
    },

    showTagDialog(item){
      this.taggingMessageId = item._id;
      this.tagDialog = true;
    },

    async tagMessage(tag, item){

      const new_tag = {
        active: true,
        id: null,
        segment_id: this.data.id,
        tag_id: tag
      }

      const response = await httpRequest.addTag(this.data.id,tag,item._id);
      new_tag.id = response.data.data.id;
      item.tags.push(new_tag);
      
      this.addSuccessMessage('Successfully added tag!');
      this.changedTags = true;
    }

  },

  created() {
    this.data.id = this.$route.params.id;
  }
};
</script>

<style scoped>

  .email-body-spaced {
    white-space: pre-wrap;
  }

  .thread-scroll {
    height: calc(100vh - 220px);
    overflow-y: scroll;
  }

</style>
