import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "@/auth/authGuard";

import Dashboard from '@/views/Dashboard.vue';
import DataSummary from '@/views/DataSummary.vue';
import Segment from '@/views/Segment.vue';
import Login from '@/Login.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: authGuard,
      component: Dashboard
  },
  {
      path: '/data/:id',
      name: 'data-segment',
      beforeEnter: authGuard,
      component: Segment
  },
  {
      path: '/data/:id/summary',
      name: 'data-summary',
      beforeEnter: authGuard,
      component: DataSummary
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
