<template> 
  <div>
    <div class="d-flex justify-end">
      <small>Through {{formatDate(currentEndDate)}}</small>
    </div>    
    <calendar-heatmap :values="visibleDates" :end-date="currentEndDate" :max="maxNum" tooltip-unit="emails"  /> 
    <div class="d-flex justify-end mt-n10">
      <v-btn x-small color="secondary" :disabled="!calendarCanNavLeft" @click="calendarNavLeft">
        <v-icon dark>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-btn x-small color="secondary" class="ml-3 mr-3" :disabled="!calendarCanNavRight" @click="calendarNavRight">
        <v-icon dark>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>    
  </div>
</template>

<script>
import _last from 'lodash/last';
import _maxBy from 'lodash/maxBy';
import formatDate from '@/plugins/formatDate';
import { CalendarHeatmap } from 'vue-calendar-heatmap';

export default {
  name: 'CalendarHeatMap',
  components: {
    CalendarHeatmap
  },
  props: {
    dates: Array
  },
  data: () => ({
    calendarCanNavLeft: false,
    calendarCanNavRight: false,
    currentEndDate: null,
    endDate: null,
    maxNum: 0,
    startDate: null,
    visibleDates: []
  }),
  
  computed: {},

  methods: {
    calendarNavLeft(){
      this.currentEndDate.setYear(this.currentEndDate.getFullYear() - 1);
      this.determineNavStatus();
    },

    calendarNavRight(){
      this.currentEndDate.setYear(this.currentEndDate.getFullYear() + 1);
      this.determineNavStatus();
    },

    determineNavStatus(){
      this.visibleDates = this.dates.filter(d => {
        return (new Date(d.date) < this.currentEndDate);
      });
      this.calendarCanNavLeft = ((this.currentEndDate - this.startDate) / (1000 * 3600 * 24*365) > 1);
      this.calendarCanNavRight = (this.currentEndDate < this.endDate);
    },
    
    formatDate(date) {
      return formatDate.formatYearMonthWithoutOffset(date);
    }
  },

  created() {
    this.startDate = new Date(this.dates[0].date);
    this.endDate = new Date(_last(this.dates).date);
    this.endDate.setDate(this.endDate.getDate() + 1); // need to add one day to the last day of data to get the last days data to appear
    this.currentEndDate = new Date(_last(this.dates).date);
    this.currentEndDate.setDate(this.currentEndDate.getDate() + 1); // need to add one day to the last day of data to get the last days data to appear
    this.maxNum = _maxBy(this.dates, f => { return f.count; }).count;
    this.determineNavStatus();
  }
};
</script>
<style>

  .vch__legend__wrapper{
    display: none;
  }

</style>
