<template> 
  <v-card style="height: 350px; overflow-y: scroll; overflow-x: hidden;" class="mb-2">
    <v-text-field dense outlined label="Filter" v-model="filter" hide-details class="mb-2" style="width: 95%;" autocomplete="off">
      <template slot="append-outer">
        <!--<v-icon @click="setFilteredParticipants" title="Update Participants Search with Filtered Results">mdi-filter-variant</v-icon>-->
        <v-icon @click="emptyParticipants" title="Clear Participants Search List">mdi-close</v-icon>
      </template>
    </v-text-field>
    <v-list>
      <v-list-group no-action dense v-for="(r, i) in filteredAvatars" :key="i">
        <template v-slot:activator>
          <v-list-item-avatar>
            <v-img :alt="r.avatar_name" :src="r.avatar_image" v-if="r.avatar_image"></v-img>
            <v-avatar color="primary" v-else><span class="white--text">{{r.avatar_name.substring(0,1).toUpperCase()}}</span></v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="r.avatar_name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-avatar color="primary" size="24"><span class="white--text" style="font-size: 10px;">{{r.doc_count}}</span></v-avatar>
          </v-list-item-action>
          <v-list-item-action class="ml-1">
            <v-btn icon small class="ml-1" @click="addAllInfo(r)" v-if="determineIfAllInfoFlagged(r) == 'none'" @click.stop="">
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </v-btn>
            <v-btn icon small class="ml-1" @click="addAllInfo(r)" v-if="determineIfAllInfoFlagged(r) == 'some'" @click.stop="">
              <v-icon>mdi-minus-box-outline</v-icon>
            </v-btn>
            <v-btn icon small color="primary accent-4" class="ml-1" @click="removeAllInfo(r)" v-if="determineIfAllInfoFlagged(r) == 'every'" @click.stop="">
              <v-icon>mdi-checkbox-marked</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
        <v-list-item v-for="(e, i) in r.associated_info" :key="i" link>
          <v-list-item-content class="text-right">
            <v-list-item-subtitle v-text="e"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ml-1" v-if="r.id == 'new'">
            <v-btn icon small class="ml-1" @click="openAvatarDialog(e)" title="Add to Avatar">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action class="ml-1">
            <v-btn icon small class="ml-1" @click="addInfo(e)" v-if="!determineIfInfoFlagged(e)">
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </v-btn>
            <v-btn icon small color="primary accent-4" class="ml-1" @click="removeInfo(e)" v-else>
              <v-icon>mdi-checkbox-marked</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-dialog v-model="avatarDialog" width="500">
			<v-card>
				<v-card-title class="text-h5 primary lighten-2">
					Add Avatar
				</v-card-title>

				<v-card-text class="pt-3">
					<span>Add the following email to a new or existing avatar:</span>
					<br />
					<div class="well mt-3 email-body-spaced">{{addAvatar}}</div>
				</v-card-text>

        <v-col cols="12">
          <v-text-field outlined v-model="avatar_name" label="Name"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete outlined v-model="avatar_selected" label="Avatar" :items="avatarList" item-text="avatar_name" item-value="id"></v-autocomplete>
        </v-col>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="saveAvatar" :disabled="!avatar_name && !avatar_selected">
						<v-icon class="mr-1">mdi-plus</v-icon> Add
					</v-btn>
					<v-btn @click="avatarDialog = false">
						<v-icon class="mr-1">mdi-close</v-icon> Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import httpRequest from "@/plugins/httpService";
import { uuid } from 'vue-uuid';

export default {
  name: 'Avatars',
  components: {},
  props: {
    dataId: String,
    avatars: Array,
    sender: Boolean
  },
  data: () => ({
    filter: '',
    avatarDialog: false,
    addAvatar: '',
    avatarList: [],
    avatar_name: '',
    avatar_selected: null
  }),
  
  computed: {  
    ...mapGetters({
      search: "app/search"
    }),
    
    filteredAvatars (){
      return this.avatars.filter(f => { return (f.avatar_name.toLowerCase().match(this.filter.toLowerCase()) || !this.filter); 
      });
    } 
  },

  methods: {
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage",
      addSearchSenders: "app/addSearchSenders",
      addSearchReceivers: "app/addSearchReceivers",
      removeSearchSenders: "app/removeSearchSenders",
      removeSearchReceivers: "app/removeSearchReceivers",
      setSearchSenders: "app/setSearchSenders",
      setSearchReceivers: "app/setSearchReceivers"
    }),

    addAllInfo(avatar){
      
      if (this.sender){
        const senders = this.search[this.dataId].senders.concat(avatar.associated_info.filter((item) => (this.search[this.dataId].senders || []).indexOf(item) < 0));
        this.setSearchSenders({id: this.dataId, info: senders});
      } else {
        const receivers = this.search[this.dataId].receivers.concat(avatar.associated_info.filter((item) => (this.search[this.dataId].receivers || []).indexOf(item) < 0));
        this.setSearchReceivers({id: this.dataId, info: receivers});
      }

      this.$forceUpdate();
    },
    
    async saveAvatar() {

      if (this.avatar_name != ''){
        const avatar = {
          id: uuid.v4(),
          avatar_name: this.avatar_name,
          avatar_image: null,
          associated_info: [].concat(this.addAvatar)
        }

        console.log(avatar);
        await httpRequest.addAvatar(this.dataId, avatar);
        this.addSuccessMessage('Successfully added avatar!');

      } else {
        await httpRequest.addAssociatedInfoToAvatar(this.avatar_selected, this.addAvatar);
        this.addSuccessMessage('Successfully added email to avatar!');
      }

      this.$emit('avatarAdded');
      this.avatar_name = '';
      this.avatar_selected = null;
      this.avatarDialog = false;
    },

    addInfo(email){
      
      if (this.sender){
        this.addSearchSenders({id: this.dataId, info: email});
      } else {
        this.addSearchReceivers({id: this.dataId, info: email});
      }
      
      this.$forceUpdate();
    },

    determineIfAllInfoFlagged(avatar){

      let some = false;
      let every = false;
      
      if (this.sender){
        some = avatar.associated_info.some(item => (this.search[this.dataId].senders || []).includes(item));
        every = avatar.associated_info.every(item => (this.search[this.dataId].senders || []).includes(item));
      } else {
        some = avatar.associated_info.some(item => (this.search[this.dataId].receivers || []).includes(item));
        every = avatar.associated_info.every(item => (this.search[this.dataId].receivers || []).includes(item));
      }

      if (every){ return 'every'; }
      if (some && !every){ return 'some'; }
      return 'none';

    },

    determineIfInfoFlagged(email){
      let index = -1;

      if (this.sender){
        index = (this.search[this.dataId].senders || []).indexOf(email);
      } else {
        index = (this.search[this.dataId].receivers || []).indexOf(email);
      }

      return (index > -1);

    },

    emptyParticipants(){

      if (this.sender){
        this.setSearchSenders({id: this.dataId, info: []});
      } else {
        this.setSearchReceivers({id: this.dataId, info: []});
      }

      this.$forceUpdate();

    },

    async openAvatarDialog(e){
      this.avatar_name = '';
      this.avatar_selected = null;
      this.addAvatar = e;
      const response = await httpRequest.getAvatars(this.dataId);
      this.avatarList = response.data.data;
      this.avatarDialog = true;
    },

    removeAllInfo(avatar){
      
      if (this.sender){

        avatar.associated_info.forEach(i => {
          this.removeSearchSenders({id: this.dataId, info: i});
        });

      } else {

        avatar.associated_info.forEach(i => {
          this.removeSearchReceivers({id: this.dataId, info: i});
        });

      }
      
      this.$forceUpdate();

    },

    removeInfo(email){
      
      if (this.sender){
        this.removeSearchSenders({id: this.dataId, info: email});
      } else {
        this.removeSearchReceivers({id: this.dataId, info: email});
      }

      this.$forceUpdate();

    }

  }
};
</script>
<style scoped>
	
  .email-body-spaced {
    white-space: pre-wrap;
  }

	.well {
		padding: 24px;
		border-radius: 6px;
		background-color: #f5f5f5;
		border: 1px solid #e3e3e3;
	}

</style>