const state = {
  authenticated: false,
  collection: {},  
  jwt: null,
  loadingData: false,
  search: {},
  snackbar: {
    color: null,
    open: false,
    text: null
  },
  user: {}
};

const getters = {
  authenticated: (state) => state.authenticated,
  collection: (state) => state.collection,
  jwt: (state) => state.jwt,
  loadingData: (state) => state.loadingData,
  search: (state) => state.search,
  user: (state) => state.user,
  snackbarColor: (state) => state.snackbar.color,
  snackbarOpen: (state) => state.snackbar.open,
  snackbarText: (state) => state.snackbar.text
};

const mutations = {
  
  ADD_SEARCH_KEYWORD(state, data) {
    state.search[data.id].keywords.push(data.keyword);
  },
  
  //SNACKBAR
  ADD_SNACKBAR_MESSAGE(state, msg) {
    state.snackbar.color = msg.color;
    state.snackbar.text = msg.text;
    state.snackbar.open = true;
  },

  CLOSE_SNACKBAR_MESSAGE(state) {
    state.snackbar.open = false;
    state.snackbar.color = null;
    state.snackbar.text = null;
  },

  ADD_TO_COLLECTION(state, data) {
    if (!state.collection[data.id]){
      state.collection[data.id] = [];
    }
    state.collection[data.id].push(data.msgId);
  },
  
  ADD_SEARCH_SENDERS(state, data) {
    state.search[data.id].senders.push(data.info);
  },
  ADD_SEARCH_RECEIVERS(state, data) {
    state.search[data.id].receivers.push(data.info);
  },

  PREP_STATE_DATA_OBJECT(state, data) {
    if (!state.search[data.id]){
      state.search[data.id] = {};
    }

    if (!state.search[data.id].keywords){
      state.search[data.id].keywords = [];
    }
    
    if (!state.search[data.id].senders){
      state.search[data.id].senders = [];
    }

    if (!state.search[data.id].receivers){
      state.search[data.id].receivers = [];
    }
    
    if (!state.search[data.id].tags){
      state.search[data.id].tags = [];
    }

    if (!state.search[data.id].pagingSize){
      state.search[data.id].pagingSize = 10;
    }

    if (!state.search[data.id].pagingFrom){
      state.search[data.id].pagingFrom = 0;
    }
    
    if (!state.search[data.id].pagingOrder){
      state.search[data.id].pagingOrder = 'asc';
    }

  },
  
  REMOVE_SEARCH_SENDERS(state, data) {
    const index = state.search[data.id].senders.indexOf(data.info);
    state.search[data.id].senders.splice(index, 1);
  },
  REMOVE_SEARCH_RECEIVERS(state, data) {
    const index = state.search[data.id].receivers.indexOf(data.info);
    state.search[data.id].receivers.splice(index, 1);
  },
  REMOVE_FROM_COLLECTION(state, data) {
    const index = state.collection[data.id].indexOf(data.msgId);
    state.collection[data.id].splice(index, 1);
  },
  REMOVE_SEARCH_KEYWORD(state, data) {
    const index = state.search[data.id].keywords.indexOf(data.keyword);
    state.search[data.id].keywords.splice(index, 1);
  },
  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_JWT(state, jwt) {
    state.jwt = jwt;
  },
  SET_LOADING_DATA(state, loading) {
    state.loadingData = loading;
  },
  SET_SEARCH_DATES(state, data) {
    state.search[data.id].startDate = data.startDate;
    state.search[data.id].endDate = data.endDate;
  },
  SET_SEARCH_PAGING(state, data) {
    state.search[data.id].pagingSize = data.pagingSize;
    state.search[data.id].pagingFrom = data.pagingFrom;
    state.search[data.id].pagingOrder = data.pagingOrder;
  },
  SET_SEARCH_SENDERS(state, data) {
    state.search[data.id].senders = data.info;
  },
  SET_SEARCH_RECEIVERS(state, data) {
    state.search[data.id].receivers = data.info;
  },
  SET_SEARCH_TAGS(state, data) {
    state.search[data.id].tags = data.tags;
  },
  SET_USER(state, user) {
    state.user = user;
  }
};

const actions = {
  
  addSuccessMessage({ commit }, msg) {
    commit("ADD_SNACKBAR_MESSAGE", { color: "success", text: msg });
  },
  addErrorMessage({ commit }, msg) {
    commit("ADD_SNACKBAR_MESSAGE", { color: "error", text: msg });
  },
  closeMessage({ commit }) {
    commit("CLOSE_SNACKBAR_MESSAGE");
  },
  addSearchKeyword({ commit }, data) {
    commit("ADD_SEARCH_KEYWORD", data);
  },
  addToCollection({ commit }, data) {
    commit("ADD_TO_COLLECTION", data);
  },
  addSearchSenders({ commit }, data) {
    commit("ADD_SEARCH_SENDERS", data);
  },
  addSearchReceivers({ commit }, data) {
    commit("ADD_SEARCH_RECEIVERS", data);
  },
  prepStateDataObject({ commit }, data) {
    commit("PREP_STATE_DATA_OBJECT", data);
  },
  removeSearchSenders({ commit }, data) {
    commit("REMOVE_SEARCH_SENDERS", data);
  },
  removeSearchReceivers({ commit }, data) {
    commit("REMOVE_SEARCH_RECEIVERS", data);
  },
  removeFromCollection({ commit }, data) {
    commit("REMOVE_FROM_COLLECTION", data);
  },
  removeSearchKeyword({ commit }, data) {
    commit("REMOVE_SEARCH_KEYWORD", data);
  },
  setAuthenticated({ commit }, authenticated) {
    commit("SET_AUTHENTICATED", authenticated);
  },
  setJwt({ commit }, jwt) {
    commit("SET_JWT", jwt);
  },
  setLoadingData({ commit }, loading) {
    commit("SET_LOADING_DATA", loading);
  },
  setSearchDates({ commit }, data) {
    commit("SET_SEARCH_DATES", data);
  },
  setSearchPaging({ commit }, data) {
    commit("SET_SEARCH_PAGING", data);
  },
  setSearchSenders({ commit }, data) {
    commit("SET_SEARCH_SENDERS", data);
  },
  setSearchReceivers({ commit }, data) {
    commit("SET_SEARCH_RECEIVERS", data);
  },
  setSearchTags({ commit }, data) {
    commit("SET_SEARCH_TAGS", data);
  },
  setUser({ commit }, user) {
    commit("SET_USER", user);
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default module;
  