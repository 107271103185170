<template>   
	<span>
		<v-btn elevated outlined small color="primary" @click="identifyFooter">
			<v-icon class="mr-1">mdi-page-layout-footer</v-icon> Footer
		</v-btn>
		
		<v-dialog v-model="footerDialog" width="500">
			<v-card>
				<v-card-title class="text-h5 primary lighten-2">
					Footer
				</v-card-title>

				<v-card-text class="pt-3" v-if="selectedText">
					<span>The following text was selected to be identified as a footer. Click "Proceed" to exclude this text from searches.</span>
					<br />
					<div class="well mt-3 email-body-spaced">{{selectedText}}</div>
				</v-card-text>
				
				<v-card-text class="pt-3" v-else>
					<span class="red--text">No text selected.</span>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" v-if="selectedText" @click="addFooter">
						<v-icon class="mr-1">mdi-check-circle-outline</v-icon> Proceed
					</v-btn>
					<v-btn @click="footerDialog = false">
						<v-icon class="mr-1">mdi-close</v-icon> Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script>
import { mapActions } from "vuex";
import httpRequest from "@/plugins/httpService";

export default {
  name: 'FooterSelection',
  components: {},
  props: {
    segment: String
  },
  data: () => ({
    selectedText: {},
  footerDialog: false
  }),
  
  computed: {},

  methods: {

    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage",
      addErrorMessage: "app/addErrorMessage",
    }),

    async addFooter() {
      await httpRequest.addFooter(this.segment, this.selectedText);
      this.addSuccessMessage('Successfully queued text to be identified and processed as a footer!');
      this.footerDialog = false;
    },

    identifyFooter() {
      this.selectedText = document.getSelection().toString();
      this.footerDialog = true;
    }

  }
};
</script>
<style scoped>
	
  .email-body-spaced {
    white-space: pre-wrap;
  }

	.well {
		padding: 24px;
		border-radius: 6px;
		background-color: #f5f5f5;
		border: 1px solid #e3e3e3;
	}

</style>