<template>
  <div class="pa-10">
    <h1 class="mb-10">{{data.title}}</h1>
    <v-row class="d-flex justify-center">
      <v-col cols="12" lg="6">
          <v-card>
          <v-list two-line>
            <template v-for="(l,index) in links">
              <v-list-item :key="l.title" :to="{name: l.href, params: {id: $route.params.id}}">
                <v-list-item-icon>
                  <v-icon large v-text="l.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="l.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="l.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
              <v-divider v-if="index < links.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'DataSummary',
  data: () => ({
    data: {
      id: 1,
      title: 'Hex Clan'
    },
    links: [{
      title: 'Email Search',
      subtitle: 'Read and tag email data',
      href: 'email-search',
      icon: 'mdi-magnify'
    },{
      title: 'Avatars',
      subtitle: 'Associate email addresses and entity names',
      href: 'email-search',
      icon: 'mdi-account-box-multiple'
    },{
      title: 'Image Review',
      subtitle: 'View and tag images',
      href: 'email-search',
      icon: 'mdi-image-multiple-outline'
    },{
      title: 'Reports',
      subtitle: 'View and print reports',
      href: 'email-search',
      icon: 'mdi-file-document-multiple-outline'
    }

    ]
  }),
  
  methods: {

    async initData() {
      //this.setLoadingData(true);
      //this.setLoadingData(false);
    }
  },

  created() {
    this.initData();
  }
};
</script>
