<template>
  <div>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SegmentEmailSearch :dataId="data.id" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SegmentEmailSearch from "@/components/Segment/EmailSearch";

export default {
  name: 'Segment',
  components: {
    SegmentEmailSearch
  },
  data: () => ({
    data: {},
    tab: 0
  }),

  methods: {
    ...mapActions({
      prepStateDataObject: "app/prepStateDataObject"
    })
  },

  created() {
    this.data.id = this.$route.params.id;
    this.prepStateDataObject(this.data);
  }
};
</script>