import * as axios from "axios";
import store from '@/store';
import indexNaming from "@/plugins/indexNaming.js";

const httpRequest = axios.create({ headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
  } 
});

httpRequest.interceptors.request.use(function (config) {
  const token = store.getters['app/jwt'];
  if (token){ config.headers = { 'X-Auth-Token': `${token}`}; }
  return config;
});

httpRequest.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response && err.response.status === 401) {
      store.commit('SET_AUTHENTICATED', false);
      store.commit('SET_JWT', null);
      store.commit('SET_USER', {});
      window.location.replace(process.env.VUE_APP_HOME_URL);
    }
  }
);

const addAvatar = async function(segment, avatar){

  let postBody = {
    commandType: 'addAvatar',
    segment: segment,
    avatar: avatar
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', postBody);
  return response;
}

const addAssociatedInfoToAvatar = async function(id, email){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', {
    commandType: 'addAssociatedInfoToAvatar',
    avatarId: id,
    associatedInfo: email
  });
  return response;
}

const addFooter = async function(segment, footer){

  let postBody = {
    commandType: 'AddFooter',
    segment: segment,
    footer: footer
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}

const addNote = async function(idToUpdate, newNote){

  let postBody = {
    commandType: 'AddNote',
    idToUpdate: idToUpdate,
    newNote: newNote
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}
  
const addTag = async function(segment, tag_id, message_id){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', {
    commandType: 'addTag',
    segment: segment,
    tag: {
      tag_id: tag_id,
      message_id: message_id
    }
  });
  return response;
}
const checkUserAccess = async function(){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'user-portal-api', {
    commandType: 'userApplications'
  });
  const appGuid = process.env.VUE_APP_ACCESS_GUID;
  if (response && response.data && response.data.data.length > 0){
    return (response.data.data.filter(d => { return d.applicationguid == appGuid }).length > 0);
  }
  return false;
}

const getAvatars = async function(index){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-avatars', {
    commandType: 'getAvatars',
    index: index
  });
  return response;
}
  
const getCollection = async function(idArray, index){

  let postBody = {
    commandType: 'GetCollection',
    idsToSearch: idArray,
    index: index
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}
  
const getDataSets = async function(){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', {
    commandType: 'GetIndices'
  });
  return response;
}
  
const getEmailSearchResults = async function(search, index, pagingSize, idsToSearch){

  let postBody = {
    commandType: 'Search',
    textToSearch: search.keywords,
    senders: (search.senders || []),
    receivers: (search.receivers || []),
    index: index,
    pagingSize: pagingSize,
    pagingFrom: (search.pagingFrom || 0),
    pagingOrder: (search.pagingOrder || 'asc'),
    idsToSearch: (idsToSearch || [])
  };

  if (search.startDate){ postBody.startDate = search.startDate; }
  if (search.endDate){ postBody.endDate = search.endDate; }

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}

const getEmailSearchResultsForDownload = async function(search, index, pagingSize, idsToSearch, tags){

  let postBody = {
    segment: indexNaming.format(index),
    commandType: 'SearchDownload',
    textToSearch: search.keywords,
    senders: (search.senders || []),
    receivers: (search.receivers || []),
    index: index,
    pagingSize: pagingSize,
    pagingFrom: 0,
    pagingOrder: (search.pagingOrder || 'asc'),
    idsToSearch: (idsToSearch || []),
    tags: (tags || [])
  };

  if (search.startDate){ postBody.startDate = search.startDate; }
  if (search.endDate){ postBody.endDate = search.endDate; }

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}

const getParticipants = async function(index){

  let postBody = {
    commandType: 'GetParticipants',
    index: index
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}
  
const getTags = async function(index){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', {
    commandType: 'getTags',
    index: index
  });
  return response;
}
  
const getThread = async function(id, index){

  let postBody = {
    commandType: 'GetThreadById',
    idToSearch: id,
    index: index
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', postBody);
  return response;
}

const getUser = async function(){
  
  let postBody = {
    commandType: ''
  };

  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'user-portal-api', postBody);
  return response;
}

const removeTag = async function(id){
  const response = await httpRequest.post(process.env.VUE_APP_SEARCH_API_URL + 'pb-search', {
    commandType: 'removeTag',
    tag: {
      id: id
    }
  });
  return response;
}

export default {
  addAvatar,
  addAssociatedInfoToAvatar,
  addFooter,
  addNote,
  addTag,
  checkUserAccess,
  getAvatars,
  getCollection,
  getDataSets,
  getEmailSearchResults,
  getEmailSearchResultsForDownload,
  getParticipants,
  getTags,
  getThread,
  getUser,
  removeTag
};