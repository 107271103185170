import moment from 'moment';

export default {
  format: function(date) {
    return moment.utc(date).local().format('YYYY-MM-DD');
  },
  
  formatFromNow: function(date) {
    return moment.utc(date).local().fromNow();
  },
  
  formatWithTime: function(date) {
    return moment.utc(date).local().format('YYYY-MM-DD hh:mm A');
  },
  
  formatWithoutOffset: function(date) {
    return moment(date).format('YYYY-MM-DD');
  },
  
  formatWithTimeWithoutOffset: function(date) {
    return moment.utc(date).format('YYYY-MM-DD hh:mm A');
  },
  
  formatYearMonthWithoutOffset: function(date) {
    return moment(date).format('YYYY-MM');
  }
  
};
